import React from 'react';

const WeddingDetails = () => {
  return (
    <div className="content-container">
      <div>
        <h1>Wedding Details</h1>
        <p>All the information about our special day...</p>
      </div>
    </div>
  );
};

export default WeddingDetails;
